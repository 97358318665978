

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --sectionTwoImg: none;
  --sectionThreeImg: none;
  --sectionFourImg: none;
  --sectionFiveImg: none;
  --sectionSixImg: none;
  --sectionSevenImg: none;
  --ayodhya_partnersummit_banner_img: none;
  --projectbackImg: none;
  --inverstedBg: none;
  --still_convience_bg_img: none;
  --downloadbackImg: none;
  --gifbackImg: none;
  --menubackicon: none;
}

@media only screen and (min-width: 1920px) { 
  body {
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(24, 36, 40);
  }
}
  
img{
  max-width: 100%;
}

@font-face {
  font-family: "Futura Md BT";
  src: url("./assets/fonts/futura/Futura\ Book\ font.ttf");
}

@font-face {
  font-family: "Futura-Medium";
  src: url("./assets/fonts/futura/futura\ medium\ bt.ttf");
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/gotham/GothamBook.ttf");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("./assets/fonts/gotham/GothamMedium.ttf");
}

/* SCROLL BAR CUSTOMIZATION STYLES */
::-webkit-scrollbar {
  /* scrollbar-color: blue; */
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #babac0;
  border-radius: 6px;
  width: 5px;
  height: 4px;
}
input, select{
    font-family: 'Futura Md BT'
}
@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 2px;
  }

  .section-heading-holder {
    margin-bottom: 0.5rem;
  }

  .section-heading-holder > h1 {
    font-size: 1rem;
  }
}
