.profile_view_details_style {
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #727272;
}
.web_profile_sticky_container {
  width: 100%;
  padding: 0 2rem;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 1000 !important;
  height: 10vh;
}
.profile_heading {
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.web_profile_border_style {
  width: 100%;
  height: 1px;
  background: #bfbfbf;
  margin-top: 10px;
}
.notification_div_style {
  width: 48px;
  height: 48px;
  background: #f0f0f0;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.message_div_style {
  width: 48px;
  height: 48px;
  background: #f0f0f0;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.profile_div_style {
  width: 196px;
  height: 65px;
  background: #f0f0f0;
  border-radius: 68px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.right_web_profile_component {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* loginsignup is commented in component */
/* .loginSignup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 189px;
  height: 35px;
  cursor: pointer;
  border-radius: 40px;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline: none !important;
  border: none !important;
} */
.profile_name_style {
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.profile_name_h3_style {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}
.profile_pic_img_style {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile_pic_div_style {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
}
.web_profile_component {
  padding: 10px 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media  (max-width: 1023px){
  .web_profile_container {
    width: 100%;
    padding: 0 2rem;
    cursor: pointer;
    /* position: fixed; */
    height: 10vh;
  }

  .web_profile_sticky_container {
    width: 100%;
    padding: 0 2rem;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 1000 !important;
    height: 10vh;
  }
  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
  }
  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }
  .web_profile_component {
    /* padding: 10px 3rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .web_profile_border_style {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    margin-top: 10px;
  }

  .icon_style {
    display: flex;
    align-items: center;
  }

  .profile_heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .right_web_profile_component {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .notification_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .profile_div_style {
    width: 196px;
    height: 65px;
    background: #f0f0f0;
    border-radius: 68px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

  .profile_pic_div_style {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
  }

  .profile_pic_img_style {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile_name_h3_style {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .profile_name_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }

  .profile_view_details_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #727272;
  }

  .loginSignup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 125px;
    height: 35px;
    cursor: pointer;
    border-radius: 40px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    outline: none !important;
    border: none !important;
  }

  .menu_dropdown_style {
    width: 280px;
  }

  /* .menu_item_style {
    display: inline-block;
    padding: 0px 25px;
    cursor: pointer;
}

.menu_item_style:not(:last-child) {
    border-right: 2px solid #ffffff;
} */

  .menu_item_container {
    position: relative;
    font-size: 0.7rem;
    padding: 0 0.7rem !important;
  }

  .menu_item_container::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    height: 10px;
    width: 1px;
    background-color: #727272;
  }

  .menu_item_container:last-child::after {
    display: none;
  }

  .header {
    padding: 0 !important;
  }
}

@media  (min-width: 1440px) {
  .web_profile_container {
    width: 100%;
    /* padding: 0px 2rem; */
    cursor: pointer;
    /* position: fixed; */
    height: 10vh;
  }

  .web_profile_sticky_container {
    width: 100%;
    padding: 0px 2rem;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 1000 !important;
    height: 10vh;
  }
  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
  }

  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
  }
  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }
  .web_profile_component {
    /* padding: 10px 3rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .web_profile_border_style {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    margin-top: 10px;
  }

  .icon_style {
    display: flex;
    align-items: center;
  }

  .profile_heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .right_web_profile_component {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .notification_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .profile_div_style {
    width: 196px;
    height: 65px;
    background: #f0f0f0;
    border-radius: 68px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

  .profile_pic_div_style {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
  }

  .profile_pic_img_style {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile_name_h3_style {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .profile_name_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }

  .profile_view_details_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #727272;
  }

  .loginSignup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 189px;
    height: 35px;
    cursor: pointer;
    border-radius: 40px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    outline: none !important;
    border: none !important;
  }

  .menu_dropdown_style {
    width: 280px;
  }

  /* .menu_item_style {
    display: inline-block;
    padding: 0px 25px;
    cursor: pointer;
}

.menu_item_style:not(:last-child) {
    border-right: 2px solid #ffffff;
} */

  .menu_item_container {
    position: relative;
    font-size: 18px;
  }

  .menu_item_container::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    height: 10px;
    width: 1px;
    background-color: #727272;
  }

  .menu_item_container:last-child::after {
    display: none;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1001px) {
  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }
  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }
}

@media (min-width: 1920px) {
  .web_profile_container {
    width: 100%;
    padding: 0 2rem;
    cursor: pointer;
    /* position: fixed; */
    height: 10vh;
  }

  .web_profile_sticky_container {
    width: 100%;
    max-width: 1920px;
    padding: 0 2rem;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 1000 !important;
    height: 10vh;
  }

  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
  }
  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
  }
  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }
  .web_profile_component {
    /* padding: 10px 3rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .web_profile_border_style {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    margin-top: 10px;
  }

  .icon_style {
    display: flex;
    align-items: center;
  }

  .profile_heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .right_web_profile_component {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .notification_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .profile_div_style {
    width: 196px;
    height: 65px;
    background: #f0f0f0;
    border-radius: 68px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

  .profile_pic_div_style {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
  }

  .profile_pic_img_style {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile_name_h3_style {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .profile_name_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }

  .profile_view_details_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #727272;
  }

  .loginSignup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 189px;
    height: 35px;
    cursor: pointer;
    border-radius: 40px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    outline: none !important;
    border: none !important;
  }

  .menu_dropdown_style {
    width: 280px;
  }

  .menu_item_container {
    position: relative;
    font-size: 28px;
  }

  .menu_item_container::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #727272;
  }

  .menu_item_container:last-child::after {
    display: none;
  }
}

/* @media only screen and (max-width: 3000px) and (min-width: 2401px) {
  .web_profile_container {
    width: 100%;
    padding: 0 2rem;
    cursor: pointer;
    height: 9vh;
  }

  .web_profile_sticky_container {
    width: 100%;
    max-width: 1920px;
    padding: 0 2rem;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 1000 !important;
    height: 9vh;
  }
  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }
  .web_profile_component {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .web_profile_border_style {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    margin-top: 10px;
  }

  .icon_style {
    display: flex;
    align-items: center;
  }

  .profile_heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .right_web_profile_component {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .notification_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .profile_div_style {
    width: 196px;
    height: 65px;
    background: #f0f0f0;
    border-radius: 68px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

  .profile_pic_div_style {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
  }

  .profile_pic_img_style {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile_name_h3_style {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .profile_name_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }

  .profile_view_details_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #727272;
  }

  .loginSignup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 189px;
    height: 35px;
    cursor: pointer;
    border-radius: 40px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    outline: none !important;
    border: none !important;
  }

  .menu_dropdown_style {
    width: 280px;
  }

  .menu_item_container {
    position: relative;
    font-size: 35px;
  }

  .menu_item_container::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #727272;
  }

  .menu_item_container:last-child::after {
    display: none;
  }
}

@media only screen and (max-width: 3600px) and (min-width: 3001px) {
  .web_profile_container {
    width: 100%;
    padding: 0 2rem;
    cursor: pointer;
    height: 8vh;
  }

  .web_profile_sticky_container {
    width: 100%;
    max-width: 1920px;
    padding: 0 2rem;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 1000 !important;
    height: 8vh;
  }
  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }
  .web_profile_component {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .web_profile_border_style {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    margin-top: 10px;
  }

  .icon_style {
    display: flex;
    align-items: center;
  }

  .profile_heading {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .right_web_profile_component {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .message_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .notification_div_style {
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .profile_div_style {
    width: 196px;
    height: 65px;
    background: #f0f0f0;
    border-radius: 68px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

  .profile_pic_div_style {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 50%;
  }

  .profile_pic_img_style {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .profile_name_h3_style {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  .profile_name_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
  }

  .profile_view_details_style {
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #727272;
  }

  .loginSignup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 189px;
    height: 35px;
    cursor: pointer;
    border-radius: 40px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: 1px;
    text-transform: uppercase;
    outline: none !important;
    border: none !important;
  }

  .menu_dropdown_style {
    width: 280px;
  }

  .menu_item_container {
    position: relative;
    font-size: 35px;
  }

  .menu_item_container::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #727272;
  }

  .menu_item_container:last-child::after {
    display: none;
  }
}

@media only screen and (min-width: 3601px) {
  .web_profile_container {
    width: 100%;
    padding: 0 2rem;
    cursor: pointer;
    height: 8vh;
  }

  .web_profile_sticky_container {
    width: 100%;
    max-width: 1920px;
    padding: 0 2rem;
    cursor: pointer;
    position: fixed;
    top: 0;
    z-index: 1000 !important;
    height: 8vh;
  }
  .web_profile_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_sticky_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }

  .web_profile_container .web_profile_component {
    height: 100%;
  }

  .web_profile_sticky_container .web_profile_component {
    height: 100%;
  }

  .web_profile_component {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .web_profile_border_style {
    width: 100%;
    height: 1px;
    background: #bfbfbf;
    margin-top: 10px;
  }

  .icon_style {
    display: flex;
    align-items: center;
  }

  


  .menu_dropdown_style {
    width: 280px;
  }

  .menu_item_container {
    position: relative;
    font-size: 40px;
  }

  .menu_item_container::after {
    content: "";
    position: absolute;
    top: 10px;
    right: 0;
    height: 20px;
    width: 1px;
    background-color: #727272;
  }

  .menu_item_container:last-child::after {
    display: none;
  }
} */
