.toast_error_message {
  background: red;
  color: #fff;
}

.closeDropdownWrapper,
.close-dropdown-wrapper {
  position: absolute;
  bottom: 40%;
  right: 30px;
  width: 24px;
  height: auto;
  transform: translateY(50%);
  z-index: 12;
}
.mobDropdownIcon {
  position: absolute;
  bottom: 12px;
  right: 0px;
  width: 24px;
  height: auto;
  transform: translateY(50%);
  z-index: 1;
}
.mobCloseDropdownIcon {
  position: absolute;
  border-radius: 4px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 14px 0px;
  z-index: 1;
  /* max-height: 13rem; */
}
.dropdownPhoneNumber {
  margin: -243px 0 10px -1px;
  background-color: black;
  color: #ffff;
}

.dropdownIconSVG {
  width: 100%;
  height: auto;
  transition: 0.2s;
  cursor: pointer;
}

.dropdownWrapper {
  position: absolute;
  bottom: 100%;
  border-radius: 4px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  height: auto;
  max-height: 480px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 14px 0px;
  z-index: 1;
}

.dropdownDownIcon {
  position: absolute;
  bottom: 40%;
  right: 0px;
  width: 24px;
  height: auto;
  transform: translateY(50%);
  z-index: 12;
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: end;
  flex: 1;
  display: block;
  height: auto;
}
/* for footer form */
.otpResendButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 24px; */
  gap: 10px;
  width: 160px;
  height: 30px;
  background: #000000 !important;
  border-radius: 88px;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff !important;
  margin-top: 2rem;
}

/* CSS FOR CUSTOM DROPDOWN */
.dropdownListText {
  font: inherit;
  padding: 4px 0px 4px 4px;
  cursor: pointer;
  color: #000000;
  margin-left: 8px;
  border-radius: 4px;
}
.dropdownListTextActive {
  background-color: #046af9 !important;
  color: #fff !important;
}
.dropdownListText:hover {
  background-color: #337ae0;
  color: #fff;
}
.mobile_input_style,.border_input_style{
  position: relative;
}
.mobileFormInput {
  width: 100%;
  /* margin-top: 2rem !important; */
  border: none;
  /* border-bottom: 1px solid gray; */
  background-color: transparent;
  outline: none;
  border: none;
  box-shadow: none;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-align: left;
  color: #000000;
}

.submitBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18.0488px 43.3171px;
  gap: 18.05px;
  width: 14%;
  height: 48px;
  background: #bfbfbf;
  border-radius: 158.829px;
  cursor: pointer;
  outline: none !important;
  border: none !important;
}

.mobileFormDropDown {
  border: none;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  overflow: none;
  width: 80%;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}

.mobileform {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 3rem; */
  position: relative;
  bottom: 2rem;
  padding: 30px;
  margin-top: 0px !important;
}

.mobileSubmitBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 24px;
  gap: 10px;
  width: 296px;
  height: 40px;
  background: #000000 !important;
  border-radius: 88px;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff !important;
  margin-top: 2rem;
  width: 100% !important;
  border: none;
  outline: none;
  cursor: pointer;
}

.closeBtn {
  z-index: 99999;
  background: black;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -18px;
  right: -10px;
  cursor: pointer;
}

.inputStyle {
  width: 100px !important;
  padding: 10px;
  /* border-bottom: 1px solid white; */
  background-color: black !important;
  border: none !important;
  /* border-bottom: 1px solid gray !important; */
  outline: none;
  color: white;
  font-size: 15px !important;
}

.cotentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobileFormInput:hover,
  .mobileFormInput:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

.mobileFormModalStyleeAyodhya {
  border: none;
  outline: none;
  width: 90%;
  height: 60v2 !important;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999999999;
  position: relative;
  top: 18rem !important;
}

.heading {
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 54px;
  color: #000000;
}

.desc {
  text-align: center;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 140%;
  color: #727272;
  margin: 20px;
}

.callWithEnqueryForm {
  width: 100%;
  position: relative;
  bottom: 10px;
  padding-top: 20px;
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.okayBtn {
  width: 252.68px;
  height: 64px;
  background: #000000;
  border-radius: 158.829px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}

.backText {
  position: relative;
  top: 0.2rem;
  left: 10px;
  font-family: "Futura Md BT";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #000000;
}



@media only screen and (max-width: 376px) and (min-width: 362px) {
  .mobileFormModalStylee {
    /* height: 73vh; */
    position: relative;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    height: 100% !important;
    position: relative;
  }

  .Loader {
    width: 56px;
    height: 56px;
  }

  .mobileFormModalStyleeAyodhya .mobileSubmitBtn {
    position: relative;
    bottom: 4rem !important;
    background: black !important;
    color: white !important;
  }
  .mobileSubmitBtn:hover {
    background: black;
    color: white;
  }
  .CountryAndCity {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0.5rem;
  }
  .roleInputStyle {
    margin: 0px;
  }
  .cityStyle {
    width: 120px !important;
  }
  .countryStyle {
    width: 120px !important;
  }
  .mobileFormModalStyleeAyodhya .mobileSubmitBtn {
    position: relative;
    top: -2rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;

    top: -0.3rem !important;
  }
}

@media only screen and (max-width: 361px) and (min-width: 330px) {
  .mobileFormModalStyleeAyodhya {
    top: 22rem !important;
    height: 65vh !important;
    z-index: 999999;
  }
  .mobileSubmitBtn {
    position: relative;
    /* bottom: 4rem !important; */
    /* margin-top: 5rem !important; */
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    height: 105% !important;
    position: relative;
  }
  .mobileFormModalStyleeAyodhya .mobileform {
    position: relative;
    bottom: 1rem;
  }
  .countryStyle {
    width: 200px !important;
    position: relative;
    left: 0.4rem !important;
  }
  .cityStyle {
    width: 200px !important;
    position: relative !important;
    right: 0.3rem !important;
  }
}

@media only screen and (max-width: 625px) {
  .navigateBack {
    cursor: pointer;
  }
  .modalStyle {
    width: 90% !important;
  }

  .enqueryCon {
    display: none;
  }

  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }

  .callNowBtn {
    background: #000000;
    border-radius: 60px;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    color: white;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    outline: none !important;
    border: none !important;
  }

  .enQueryBtn {
    border-radius: 60px;
    background-color: transparent;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    border: none;
    border: 2px solid gray;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
  }

  .mobileFormInput {
    margin-top: 1rem;
    padding: 0px !important;
  }

  .phoneInput {
    margin-top: 1rem;
  }

  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }

  .mobileSubmitBtn:hover {
    background: black !important;
    color: white !important;
  }

  .navigateBack {
    display: flex;
    position: relative;
    right: 5px;
  }

  .phone_number_style {
    text-decoration: none !important;
    color: #ffffff;
  }
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 90%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999 !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 10%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 10%;
    position: absolute;
    right: 0px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 95%;
    position: relative;
    margin: 10px 0px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 10% !important;

    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 120px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .mobileFormModalStyleeAyodhya {
    height: 65vh;
    z-index: 999999;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    right: 3rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .mobileFormModalStyleeAyodhya .mobileSubmitBtn {
    position: relative;
    bottom: 2rem;
  }
  .countryStyle {
    width: 250px;
    position: relative;
    left: 0.1rem;
  }
  .cityStyle {
    width: 250px;
    position: relative;
    right: 0.3rem;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 626px) {
  .mobileView {
    /* display: none; */
  }
  .Loader {
    width: 56px;
    height: 56px;
  }

  .enqueryCon {
    position: sticky;

    bottom: 0px;
    width: 100%;
    height: 55px;
    left: 0;
    background-color: black;
    z-index: 1000;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 160px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .heading {
    font-size: 40px;
  }

  .desc {
    font-size: 18px;
  }

  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
    margin-top: 1rem;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }
  .mobileSubmitBtn {
    padding: 10px 24px;
  }
  .navigateBack {
    display: flex;
  }
  .backText {
    top: 3px;
  }
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999 !important;
  }
  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap !important;
  }
  .roleInputDiv {
    width: 40%;
    position: relative;
    margin: 10px;
    display: flex !important;
    flex-wrap: wrap;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 180px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .mobileFormModalStyleeAyodhya {
    width: 50%;
    height: 100vh;
    z-index: 999999;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1800px) and (min-width: 1024px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    width: 50%;
    height: 92vh;
    z-index: 999999;
  }

  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }

  .mobileFormInput {
    margin-top: 1.5rem;
  }
  
  .phoneInput {
    margin-top: 1rem;
  }
  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  /* .phoneInputFont {
    margin-bottom: 10px;
  } */
  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 8vh;
    left: 0;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 215px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    text-align: left !important;
    font-size: 14px !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 15px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 230px !important;
    margin-bottom: 3px;
    font-size: 14px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    padding: 18.0488px 30.3171px;
    width: 152.68px;
    height: 40px;
  }

  .heading {
    font-size: 40px;
  }

  .desc {
    font-size: 16px;
  }

  .mobileSubmitBtn {
    padding: 10px 24px;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    top: 3px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 1800px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 99999999;
  }
  .mobileFormModalStyleeAyodhya {
    width: 50%;
    height: 62vh;
    z-index: 99999999;
    margin-top: 2rem;
  }

  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .mobileSubmitBtn {
    width: 100% !important;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }

  .mobileFormInput {
    margin-top: 3rem;
    font-size: 20px;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .phoneInput {
    margin-top: 3rem;
  }
  .phoneInputFont {
    margin-top: 7px;
  }
  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 8vh;
    background-color: black;
    z-index: 1000;

    position: fixed;
    bottom: 0;
  }

  .inputStyle {
    width: 230px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 20px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 25px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    font-size: 20px;
    width: 230px !important;
    margin-bottom: 2px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    padding: 18.0488px 30.3171px;
    width: 160;
    height: 48px;
    font-size: 25px;
  }

  .heading {
    font-size: 40px;
  }

  .desc {
    font-size: 16px;
  }

  .mobileSubmitBtn {
    padding: 10px 24px;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    top: 3px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 1920px) {
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: end;
    flex: 1;
  }
}

@media only screen and (min-width: 2400px) {
  .phoneInput {
    height: 45px !important;
    font-size: 30px !important;
  }

  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;

    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .Loader {
    width: 56px;
    height: 56px;
  }
  .mobileFormModalStyleeAyodhya {
    width: 50%;
    height: 92vh;
    z-index: 999999;
  }

  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    font-size: 30px;
  }

  .mobileFormInput {
    margin-top: 3rem;
    font-size: 30px;
    padding: 1rem;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 140%;
    color: #000000;
  }
  .phoneInput {
    margin-top: 5rem;
  }
  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 8vh;
    background-color: black;
    z-index: 1000;
    position: fixed;
    bottom: 0;
    max-width: 1910px;
  }

  .inputStyle {
    width: 230px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 20px !important;
    text-align: left !important;
  }
  .phoneInputFont {
    font-size: 20px !important;
    margin-top: 5px;
  }
  .border_input_style {
    border-bottom: 1px solid gray !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 24px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    width: 230px !important;
    margin-bottom: 5px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    padding: 18.0488px 30.3171px;
    width: 160px;
    height: 40px;
  }

  .heading {
    font-size: 40px;
  }

  .desc {
    font-size: 16px;
  }

  .mobileSubmitBtn {
    padding: 10px 24px;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    top: 3px;
    font-size: 40px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 3000px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 50%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .mobileFormModalStyleeAyodhya {
    width: 50%;
    height: 92vh;
    z-index: 999999;
  }

  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    width: 90px;
    height: 90px;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    font-size: 40px;
  }

  .mobileFormInput {
    margin-top: 2rem;
    font-size: 40px;
    padding: 1rem;
  }

  .phoneInput {
    height: 65px !important;
    font-size: 30px !important;
  }

  .phoneInput {
    margin-top: 3.5rem;
  }
  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 8vh;
    background-color: black;
    z-index: 1000;
  }

  .inputStyle {
    width: 230px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 35px !important;
    text-align: left !important;
  }

  .phoneInputFont {
    font-size: 28px !important;
  }
  .border_input_style {
    border-bottom: 1px solid gray !important;
  }
  .border_input_style .form-control {
    font-size: 30px;
  }
  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 38px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    font-size: 35px;

    width: 300px !important;
    margin-top: 50px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    padding: 18.0488px 30.3171px;
    width: 250px;
    height: 80px;
    font-size: 42px;
  }

  .heading {
    font-size: 40px;
  }

  .desc {
    font-size: 16px;
  }

  .mobileSubmitBtn {
    padding: 10px 24px;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    top: 3px;
    font-size: 40px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
}

@media only screen and (min-width: 3600px) {
  .mobileFormModalStylee {
    border: none;
    outline: none;
    width: 30%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999;
  }
  .Loader {
    width: 70px;
    height: 70px;
  }
  .mobileFormModalStyleeAyodhya {
    width: 50%;
    height: 92vh;
    z-index: 999999;
  }

  .innerContent {
    border: 1px solid black;
    border-top: none;
    width: 100%;
    position: relative;
  }
  .closeBtn {
    width: 100px;
    height: 100px;
    top: -35px;
    right: -25px;
  }
  .navigateBack {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 15px;
  }

  .mobileSubmitBtn {
    width: 100% !important;
  }

  .forBorderLeft {
    border-top: 1px solid black;
    width: 33%;
  }
  .forBorderRight {
    border-top: 1px solid black;
    width: 30%;
    position: absolute;
    right: 0px;
  }
  .mobileView {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: white;
    z-index: 9999999;
  }
  .mobile_input_style {
    border-bottom: 1px solid gray;
    width: 100%;
  }
  .mobileFormDropDown {
    width: 100%;
    font-size: 48px;
  }

  .mobileFormInput {
    margin-top: 1rem;
    font-size: 40px;
    padding: 2rem;
  }

  .mobileFormInput::placeholder {
    position: absolute;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 140%;
    color: #000000;
  }

  .callWithEnqueryForm {
    display: none;
  }

  /* end of modal style */
  .enqueryCon {
    width: 100%;
    height: 8vh !important;
    background-color: black;
    z-index: 1000;
  }
  .border_input_style .form-control {
    font-size: 30px;
  }
  .inputStyle {
    width: 300px !important;
    padding: 30px;
    background-color: transparent !important;
    box-shadow: none !important;
    font-size: 42px !important;
    text-align: left !important;
  }

  .border_input_style {
    border-bottom: 1px solid gray !important;
  }
  .phoneInputFont {
    font-size: 35px !important;
    margin-top: 55px;
  }
  .inputPhone {
    height: 80px !important;
    font-size: 30px !important;
    width: 300px !important;
  }

  .inputStyle:hover,
  .inputStyle:focus {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .inputStyle::placeholder {
    font-size: 35px;
    background-color: transparent !important;
  }

  .dropDownStyle {
    background-color: black;
    border: none !important;
    border-bottom: 1px solid gray !important;
    outline: none;
    color: white;
    padding: 10px;
    width: 350px !important;
    margin-top: 50px;
  }

  .enqFormStyle {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .submitBtn {
    padding: 18.0488px 30.3171px;
    width: 350px;
    height: 120px;
    font-size: 42px !important;
  }

  .heading {
    font-size: 40px;
  }

  .desc {
    font-size: 16px;
  }

  .mobileSubmitBtn {
    padding: 10px 24px;
    height: 100px;
    font-size: 48px;
    margin-top: 3rem;
  }
  /* .mobileSubmitBtn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 296px;
    height: 40px;
    background: #000000 !important;
    border-radius: 88px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff !important;
    margin-top: 2rem;
  } */
  .navigateBack {
    display: flex;
  }
  .backText {
    top: 3px;
    font-size: 48px;
  }
  .roleStyle {
    display: flex;
    flex-wrap: wrap;
  }
  .roleInputDiv {
    width: 45%;
    position: relative;
    margin: 10px;
    display: flex !important;
  }
  .roleInputDiv span {
    position: relative;
    top: 5px;
    left: 2.5rem;
  }
  .roleInputStyle {
    padding: 0px !important;
    width: 8% !important;
    cursor: pointer;
    position: absolute;
  }
  .roleHeading {
    position: relative;
    right: 260px;
    margin-top: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
  }
  .CountryAndCity {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 1rem;
  }
  .cityErrorMsg {
    color: red;
    position: relative;
    left: 6.5rem;
    top: 0.5rem;
  }
  .roleErrorMsg {
    color: red;
    position: relative;
    left: 0.5rem;
    top: 0.5rem;
  }
  .closeIcon {
    width: 80px;
    height: 80px;
  }

  .phoneInput,
  .flag-dropdown,
  .enquery {
    height: 65px !important;
    font-size: 30px !important;
  }
}
@media only screen and (min-width: 4200px) {
  .dropDownStyle {
    /* margin-top: 27px; */
  }
}

.enquery .selected-flag {
  /* background-color: #000000; */
}
.footerEnquiry li {
  color: #6b6b6b;
}
@media (max-width:800px){
  .enqueryCon {
    display: none;
  }
  .callWithEnqueryForm{
    width:100%;
    display: flex;
    justify-content: space-around;
  }
  .enQueryBtn {
    border-radius: 60px;
    background-color: transparent;
    width: 164px;
    height: 40px;
    left: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-family: "Futura Md BT";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    outline: none;
    border: none;
    border: 2px solid gray;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
  }
}